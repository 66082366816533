import { useStaticQuery, graphql } from 'gatsby'
import React from 'react';
import ReactHtmlParser from 'react-html-parser'

function REQS ({pageContext, location}) {
  const data = useStaticQuery(graphql`
  {
    allNodeParameters {
      nodes {
        field_app_requirements {
          value
        }
      }
    }
  }
`)
  let parsedData = data.allNodeParameters.nodes[0].field_app_requirements
  ? ReactHtmlParser(data.allNodeParameters.nodes[0].field_app_requirements.value)
  : ''

  const REQLayout = (
    <div>
      <div className="faqsansw">
        {parsedData}
        <br/>
        </div>
    </div>
  );
  return REQLayout;
}
export default function REQPage({ pageContext, location }) {
  pageContext = {
    ...pageContext,
    frontmatter: { title: "HEED Retreat Requirements", tabs: ["Profile", "REQS"] },
  };
  return (
    <REQS
      pageContext={pageContext}
      location={location}
    />
  );
}
